<template lang="pug">
.fgp(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-container.d-flex.align-center.justify-center.w-100
    v-row.justify-center.mt-5(style="flex-shrink: 1; flex-grow: 1")
      v-col.d-flex.justify-center.align-center(cols="12")
        h1.text-center.login-title 會員手機號碼驗證
      v-col.d-flex.justify-center.align-center(cols="12")
        p.text_primary.text-center 請驗證您的手機號碼，得以繼續享有GC會員服務！
      v-col(cols="10", md="5")
        form.form-login(method="post")
          .input_container.d-flex(:class="{ waiting: !sendPhoneCodeEnable }")
            label.sr-only(for="id_mobile") 手機號碼
            select.code(v-model="mobileCode", ref="codeselect")
              option(v-for="(code, index) in codeList", :key="index") {{ code }}
            input#id_mobile(
              required,
              type="tel",
              name="mobile",
              :placeholder="'範例: ' + phoneExample[mobileCode]",
              autofocus="",
              v-model="mobile"
            )
            svg-icon(iconClass="mobile_hollow", className="mobile_hollow")
            .send_verify_code_btn(@click="sendPhoneCode") {{ getSendPhoneCodeBtnText }}
          .input_container.d-flex.mt-6
            label.sr-only(for="id_verify") 請輸入驗證碼
            input#id_verify(
              required,
              type="text",
              name="verify",
              placeholder="請輸入驗證碼",
              autofocus="",
              v-model="phoneCode"
            )
            .verify_btn(@click="verifyMobile") 驗證
      v-col.d-flex.justify-center.align-center(cols="12")
        v-footer(align="center")
          .footer
            h5 Copyright © 2022 競晰股份有限公司
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import { sendPhoneCode, verifyPhoneCode } from "@/api/user";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";

export default {
  name: "VerifyMobile",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      mobileCode: "+886",
      mobile: "",
      phoneCode: "",
      codeList: ["+886", "+852", "+86"],
      countryDict: {
        "+886": "TW",
        "+852": "HK",
        "+86": "CHINA",
      },
      phoneExample: {
        "+886": "0912345678",
        "+852": "91234567",
        "+86": "13123456789",
      },
      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "驗證手機號碼 | Game Changer",
        description: "驗證手機號碼",
        keywords: "Game changer,籃球,會員,驗證手機號碼",
        url: "https://www.gamechanger.tw/forgetpassword",
        image: "",
        smoType: "website",
      },

      sendVerifyCountdown: null,
      sendPhoneCodeEnable: true, //是否可傳送驗證碼
      verifyStatus: 0, //0: 預設 1:驗證碼已發送過
      countdown: 180,
    };
  },
  methods: {
    sendPhoneCode() {
      if (this.mobileCode == "" || this.mobile == "") {
        this.showErrorDialog("錯誤", "請填寫正確手機號碼");
        return;
      }

      if (!this.sendPhoneCodeEnable) {
        return;
      }

      let inputData = {
        phone: this.mobileCode + this.mobile,
        country: this.countryDict[this.mobileCode],
      };

      sendPhoneCode(inputData)
        .then(() => {
          this.sendPhoneCodeEnable = false;
          this.verifyStatus = 1; //0: 預設 1:驗證碼已發送過 2:驗證成功
          var self = this;

          this.sendVerifyCountdown = setInterval(() => {
            if (self.countdown == 0) {
              clearInterval(this.sendVerifyCountdown);

              self.sendPhoneCodeEnable = true;
              self.countdown = 30;
            } else {
              self.countdown = self.countdown - 1;
            }
          }, 1000);
        })
        .catch((error) => {
          this.showErrorDialog("發送失敗", error.response.data.error_msg);
          return;
        });
    },
    verifyMobile() {
      if (this.mobileCode == "" || this.mobile == "") {
        this.showErrorDialog("錯誤", "請填寫正確手機號碼");
        return;
      }

      if (this.phoneCode == "") {
        this.showErrorDialog("錯誤", "請輸入驗證碼");
        return;
      }

      let inputData = {
        phone: this.mobileCode + this.mobile,
        phone_code: this.phoneCode,
        country: this.countryDict[this.mobileCode],
      };

      verifyPhoneCode(inputData)
        .then(() => {
          clearInterval(this.sendVerifyCountdown);
          this.showSuccessDialog("手機號碼驗證成功");
          this.$router.go(-1);
        })
        .catch((error) => {
          this.showErrorDialog("驗證失敗", error.response.data.error_msg);
          return;
        });
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
  computed: {
    getSendPhoneCodeBtnText() {
      if (this.sendPhoneCodeEnable) {
        return "發送簡訊";
      } else {
        return "重新發送(" + this.countdown + ")";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 24px;
  color: #3e3e3e;
  margin-left: 8px;
}

.theme--light.v-footer {
  background: transparent;
}

.footer {
  color: #ababab;
}

.input_container {
  position: relative;

  input {
    border: #ababab 1px solid;
    border-radius: 3px;
    background-color: #fff;
    padding-left: 36px;
  }

  svg {
    content: "";
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  .mobile_hollow {
    left: calc(12px + 24%);
  }

  input#id_verify {
    width: 70%;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  input#id_verify {
    padding-left: 12px;
  }

  #id_mobile {
    width: 46%;
    border-radius: 0;
  }

  .send_verify_code_btn,
  .verify_btn {
    border-radius: 0 2px 2px 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 1px solid #ababab;
    border-right: 1px solid #ababab;
    border-bottom: 1px solid #ababab;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
  }

  .verify_btn {
    width: 30%;
  }

  .code {
    width: 24%;
    border: #ababab 1px solid;
    background-color: #fff;
    border-radius: 2px 0 0 2px;
    border-right: none;
  }

  .send_verify_code_btn {
    width: 30%;
    background: #51769d;
  }

  .verify_btn {
    background: #002442;
  }
}

.input_container.waiting {
  .send_verify_code_btn {
    background: #b5b5b5;
    cursor: default;
  }
}

.input_container.successing {
  input#id_mobile {
    border: none;
  }

  .send_verify_code_btn {
    background: white;
    color: #1f1f1f;
    border: none;

    &::after {
      content: " ∨";
      color: #71a269;
      font-weight: bold;
    }
  }
}
</style>
